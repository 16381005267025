<template>
<container>
        <inner-page-card class="" :scrollbar="true" heading="DICTIONARY">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" @click="userAdd" border-radius="0"></btn>
                    <modal width="50r" ref="dictionaryexcelViewModal">
                        <s-form @submit="addItem">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="font-weight-700 text-2">Excel file<span class='text-danger'>*</span>:</p>
                                <validated-file-input v-model="model.file" border-radius="0" :rules="{required: false}"></validated-file-input>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                        </div>
                            </s-form>
                    </modal>
                </slot>
            </template>

            <vue-table-card :fields="fields" :url="listUrl"
            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="dictionaryexcelEditModal">
                        <s-form @submit="addItem">
                            <row>
                                 <div class="col-lg-6">
                                <validated-input label="Title <span class='text-danger'>*</span>:" v-model="editingItem.title" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-6">
                                <form-group
                                        :disabled="loading" label="Description <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="editingItem.description" ></ckeditor>
                                    </form-group>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="dictionaryexcelDeleteModal" :url="deleteUrl" :params="{id:deletingItem.id}" @response="deleteSuccess">
                        <p>You are about to delete a <b>{{deletingItem.title}}</b>. <br>Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import tableFields from './dictionary-excel-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'DictionaryExcelPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            addUrl: urls.disease.dictionaryExcelUpload.addEdit,
            editUrl: urls.disease.dictionary.addEdit,
            listUrl: urls.disease.dictionary.list,
            deleteUrl: urls.disease.dictionary.delete,
            editingItem: '',
            deletingItem: '',
            fields: tableFields,
            data: [
                {
                    title: 'Abrasion',
                    description: 'A surface discontinuity caused by roughening or scratching or it is a wearing away of the upper layer of skin. It involve minimal bleeding. Abrasions are not deep wounds'
                }
            ],
            model: {
                file: ''
            },
            editor: ClassicEditor,
            editorConfig: {}
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    this.editingItem.disease = this.$route.params.id;
                    console.log('in edit');
                    this.response = await axios.form(this.editUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.dictionaryexcelEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.dictionaryexcelViewModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;
                    const errorMessage = this.response.data.message;

                    if (errorMessage) {
                        console.log('---------------------------', errorMessage);
                        this.$notify(errorMessage.__all__, 'Warning', { type: 'warning' });
                        // this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        // for (const key in errorMessage){
                        //     if (Object.prototype.hasOwnProperty.call(errorMessage, key)) {
                        //         if (key === '__all__') {
                        //             this.$notify(errorMessage[key], 'Warning', { type: 'warning' });
                        //         } else {
                        //             this.$notify(`${key}: ${errorMessage[key]}`, 'Warning', { type: 'warning' });
                        //         }
                        //     }
                        // }
                    }
                    // -------------------------------------------------------------------------------------
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        deleteSuccess (item) {
            this.$refs.dictionaryexcelDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.dictionaryexcelEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.dictionaryexcelDeleteModal.show();
        },
        userAdd () {
            this.editingItem = '';

            this.$refs.dictionaryexcelViewModal.show();
        }
    }
};
</script>

<style scoped>

</style>
