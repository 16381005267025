<template>
     <div>
        <container>
            <inner-page-card heading="Diseases">
                <!--Add Disease Page-->
                <s-form @submit="addItem" ref="form">
                    <row>
                        <div class="col-lg-6">
                            <validated-input label="Heading <span class='text-danger'>*</span>:" v-model="model.heading" :rules="{required:true}"/>
                        </div>
                        <div class="col-lg-6 mb-6">
                            <validated-ajax-select :url="catogaryURL"  label="Species <span class='text-danger'>*</span>:" class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" v-model="model.disease_cat" :rules="{required: true}"/>
                        </div>
                        <col-12>
                            <submit-btn type="submit" :loading="loading" loading-text="saving..." @click="gotoRoute('/add-disease-detailsPage/')"/>
                        </col-12>
                    </row>
                </s-form>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import InnerPageCard from '../../../components/InnerPageCard';
import axios from 'secure-axios';
import urls from '@/data/urls';
export default {
    name: 'AddDiseasePage',
    components: { InnerPageCard },
    data () {
        return {
            loading: false,
            URL: urls.disease.disease.addEdit,
            catogaryURL: urls.disease.diseaseCategory.vueSelect,
            model: {
                heading: '',
                disease_cat: ''
            }
        };
    },
    methods: {
        async addItem () {
            console.log('add Item function calling');
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    const item = result.data;
                    console.log('data fter ssaving', item);
                    /* this.$emit('success'); */
                    this.$router.push('/disease-list-details-page/' + item.id + '/');
                } else {
                    const errors = response.data.errors;
                    // const errormessage = response.data.message;
                    // console.log('addItem check error', errormessage);

                    for (const key in errors) {
                        console.log('data fter ssaving', key);
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
